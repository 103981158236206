import { Pagination } from '@/domain/entities/Pagination'
import {
  CourierOnLeave,
  ManageCourier,
  ManageCourierAdjustSaldo,
  ManageCourierBankInformation,
  ManageCourierDetail,
  ManageCourierOnLeaves,
  ManageCouriers,
  ManageCouriersAdjustSaldo,
  Partner,
  ManageCourierHistoryLog,
  CourierHistoryLog,
  ManageCourierHistoryLogs,
  ManageCourierOnLeave,
  ManageCourierUploadBulk,
  ManageCourierHistoryUploadBulk,
  ManageCourierHistoryUploadBulks,
  TruckVendor,
  ManageCourierLeaveRequest,
  ManageCourierLeaveRequests,
} from '@/domain/entities/ManageCourier'
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { container } from 'tsyringe'
import { ManageCourierPresenter } from '@/app/ui/presenters/ManageCourierPresenter'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import Vue from 'vue'
import {
  AddCourierRequest,
  CourierLeaveApproval,
  EditCourierRequest,
  UploadBulkyManageCourierLeaveRequest,
} from '@/data/payload/api/ManageCourierRequest'
import {
  EnumErrorUploadBulk,
  EnumStatusUpload,
} from '@/app/infrastructures/misc/Constants/manageCourier'

export interface ManageCourierState {
  isLoading: boolean
  isLoadingExport: boolean
  isLoadingDownload: boolean
  isLoadingGetHistoryUploadBulk: boolean
  isLoadingGetUploadBulkData: boolean
  isLoadingSubmitBulk: boolean
  isUploading: boolean
  paginationData: Pagination
  manageCourierData: ManageCourier[]
  manageCourierAdjustSaldoData: ManageCourierAdjustSaldo[]
  manageCourierHistoryLogData: ManageCourierHistoryLog
  manageCourierOnLeaveData: ManageCourierOnLeave[]
  manageCourierDetail: ManageCourierDetail
  manageCourierHistoryUploadBulkData: ManageCourierHistoryUploadBulk[]
  partnerData: Partner[]
  exportParams: IExportParams | null
  dataUploadBulky: ManageCourierUploadBulk
  filenameBulkUpload: string
  statusUpload: EnumStatusUpload
  uploadBulkPaginationData: Pagination
  errSubmitBulky: string
  errUploadBulky: string
  truckVendorIdData: TruckVendor[]
  truckVendorNameData: TruckVendor[]
  isTruckVendorLoading: boolean
  courierLeaveRequestData: ManageCourierLeaveRequest[]
  totalLeaveRequest: number
  isSuccessApproveCourierLeave: boolean
  isSuccessRejectCourierLeave: boolean
  isFailedSubmitCourierLeave: boolean
}

export interface HasChangedType {
  driverName: boolean
  phoneNumber: boolean
  courierType: boolean
  announcementCourierType: boolean
  vehicleType: boolean
  vehiclePlate: boolean
  partnerName: boolean
  bankAccountName: boolean
  bankName: boolean
  bankAccount: boolean
  vendorName: boolean
  vendorId: boolean
  courierIsCod: boolean
}

export interface FormType {
  driverName: string
  phoneNumber: string
  vehiclePlate: string
  bankAccountName: string
  bankAccount: string
}

export interface IExportParams {
  driverType: string
  q: string
  courierStatus: string
  sortBy: string
}

@Module({ namespaced: true, store, name: 'manageCourier', dynamic: true })
class ManageCourierController extends VuexModule implements ManageCourierState {
  private presenter: ManageCourierPresenter = container.resolve(
    ManageCourierPresenter
  )
  public isLoading = false
  public isLoadingExport = false
  public isLoadingDownload = false
  public isLoadingGetHistoryUploadBulk = false
  public isLoadingGetUploadBulkData = false
  public isLoadingSubmitBulk = false
  public isUploading = false
  public paginationData = new Pagination(1, 100)
  public uploadBulkPaginationData = new Pagination()
  public manageCourierData = [new ManageCourier()]
  public manageCourierAdjustSaldoData = [new ManageCourierAdjustSaldo()]
  public manageCourierHistoryLogData = new ManageCourierHistoryLog(
    new ManageCourier(),
    [new CourierHistoryLog()]
  )
  public manageCourierOnLeaveData = [new ManageCourierOnLeave()]
  public manageCourierDetail = new ManageCourierDetail(
    0,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    new ManageCourierBankInformation('', '', '', ''),
    ''
  )
  public manageCourierHistoryUploadBulkData = [
    new ManageCourierHistoryUploadBulk(),
  ]
  public partnerData = [new Partner()]
  public dataUploadBulky = new ManageCourierUploadBulk()
  public modalSuccessAdd = false
  public modalSuccessEdit = false
  public exportParams = {
    driverType: '',
    q: '',
    courierStatus: '',
    sortBy: '',
  }
  public filenameBulkUpload = ''
  public statusUpload = EnumStatusUpload.START
  public errUploadBulky = ''
  public errSubmitBulky = ''
  public truckVendorIdData: TruckVendor[] = []
  public truckVendorNameData: TruckVendor[] = []
  public isTruckVendorLoading = false
  public courierLeaveRequestData: ManageCourierLeaveRequest[] = []
  public totalLeaveRequest = 0
  public isSuccessApproveCourierLeave = false
  public isSuccessRejectCourierLeave = false
  public isFailedSubmitCourierLeave = false
  public isFailedRejectCourierLeave = false

  @Action({ rawError: true })
  public getManageCourierList(params: Record<string, string | number>): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setManageCourierData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manage Courier Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getCourierDetail(form: { id: string }): void {
    this.setLoading(true)

    this.presenter
      .getDetail(form.id)
      .then(res => {
        this.setCourierDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Courier Detail Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public createCourier(form: {
    fullname: string
    phoneNumber: string
    courierType: string
    vehicleType: string
    vehiclePlate: string
    partnerName: string
    bankAccountName: string
    bankId: string
    bankAccount: string
    announcementCourierType: string,
    truckVendor?: {
      vendorId: string,
      vendorName: string
    },
    courierIsCod?: string
  }): void {
    this.setLoading(true)

    this.presenter
      .addCourier(
        new AddCourierRequest(
          form.fullname,
          form.phoneNumber,
          form.courierType,
          form.vehicleType,
          form.vehiclePlate,
          form.partnerName,
          form.bankAccountName,
          form.bankId,
          form.bankAccount,
          form.announcementCourierType,
          form.truckVendor,
          form.courierIsCod
        )
      )
      .then(() => {
        this.setModalSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Add Courier Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateCourier(form: {
    courierId: string
    fullname: string
    phoneNumber: string
    courierType: string
    courierStatus: string
    vehicleType: string
    vehiclePlate: string
    partnerName: string
    bankAccountName: string
    bankId: string
    bankAccount: string
    announcementCourierType: string
    timeZone: string
    courierOnLeave: CourierOnLeave[],
    truckVendor?: {
      vendorId: string,
      vendorName: string
    },
    courierIsCod?: string
  }): void {
    this.setLoading(true)

    this.presenter
      .editCourier(
        form.courierId,
        new EditCourierRequest(
          form.fullname,
          form.phoneNumber,
          form.courierType,
          form.courierStatus,
          form.vehicleType,
          form.vehiclePlate,
          form.partnerName,
          form.bankAccountName,
          form.bankId,
          form.bankAccount,
          form.announcementCourierType,
          form.timeZone,
          form.courierOnLeave.map(item => {
            return new CourierOnLeave(
              item.id,
              item.dateLeave,
              item.startTime,
              item.endTime,
              item.isCustomLeave,
              item.isDelete
            )
          }),
          form.truckVendor,
          form.courierIsCod
        )
      )
      .then(() => {
        this.setModalSuccessEdit(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Courier Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getManageCourierListAdjustSaldo(
    params: Record<string, string | number>
  ): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getCouriersAdjustSaldo(formattedParams)
      .then(res => {
        this.setManageCourierAdjustSaldoData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manage Courier Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getAllPartner(params: Record<string, string | number>): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAllPartner(formattedParams)
      .then(res => {
        if (res) this.setPartnerData(res)
      })
      .catch(error => {
        if (params.search) {
          Vue.notify({
            title: 'Fetch Partner List Failed',
            text: [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public exportCourierList(params: IExportParams) {
    this.setLoadingExport(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .export(formattedParams)
      .then(res => {
        const anchor = document.createElement('a')
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(res)
        anchor.target = '_blank'
        anchor.download = 'Manage Courier.csv'
        anchor.click()
      })
      .catch(error => {
        Vue.notify({
          title: 'Export Manage Courier Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingExport(false)
      })
  }

  @Action({ rawError: true })
  public getManageCourierHistoryLogList(payload: {
    params: Record<string, string | number>
    courierId: string
  }): void {
    this.setLoading(true)
    this.setManageCourierHistoryLogData(new ManageCourierHistoryLogs(
      new Pagination(),
      new ManageCourierHistoryLog(
        new ManageCourier(),
        [new CourierHistoryLog()]
      )
    ))
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(payload.params),
      'snake_case'
    )
    this.presenter
      .getAllHistoryLog(formattedParams, payload.courierId)
      .then(res => {
        this.setManageCourierHistoryLogData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manage Courier History Log Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getManageCourierOnLeaveList(
    params: Record<string, string | number>
  ): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAllCourierOnLeave(formattedParams)
      .then(res => {
        this.setManageCourierOnLeaveData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manage Courier Leave Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public downloadTemplate(): void {
    this.setLoadingDownload(true)

    this.presenter
      .dowloadTemplate()
      .then(res => {
        window.open(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Download Template Manage Courier Leave Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getListUploadBulk(params: Record<string, string | number>): void {
    this.setLoadingGetUploadBulkData(true)
    
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getUploadBulkData(formattedParams)
      .then(res => {
        this.setManageCourierUploadBulk(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Upload Bulk Data List Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingGetUploadBulkData(false)
      })
  }

  @Action({ rawError: true })
  public uploadBulky(payload: { file: File }): void {
    this.setFilenameBulkUpload(payload.file.name)
    this.setStatusUpload(EnumStatusUpload.PROCESSING)
    this.setErrUploadBulky('')
    this.presenter
      .uploadBulky(new UploadBulkyManageCourierLeaveRequest(payload.file))
      .then(res => {
        this.setManageCourierUploadBulk(res)
        this.setStatusUpload(EnumStatusUpload.COMPLETE)
        this.setErrUploadBulky(
          EventBusConstants.UPLOAD_BULKY_MANAGE_COURIER_LEAVE
        )
      })
      .catch(error => {
        if (!navigator.onLine) {
          this.setErrUploadBulky(EnumErrorUploadBulk.OFFLINE)
        } else if (error.error?.message) {
          this.setErrUploadBulky(error.error.message.en)
        } else {
          this.setErrUploadBulky(EnumErrorUploadBulk.UNKNOWN)
        }
        this.setStatusUpload(EnumStatusUpload.FAILED)
        if (!this.errUploadBulky.includes('is already uploaded')) {
          Vue.notify({
            title: 'Upload Bulky Failed',
            text: [400, 422].includes(error.status)
              ? error.error?.message?.en
              : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      })
  }

  @Action({ rawError: true })
  public submitBulk(): void {
    this.setLoadingSubmitBulk(true)

    this.presenter
      .submitBulk()
      .then(() => {
        this.setErrSubmitBulky(
          EventBusConstants.SUBMIT_BULKY_MANAGE_COURIER_LEAVE
        )
      })
      .catch(error => {
        if (!navigator.onLine) {
          this.setErrUploadBulky(EnumErrorUploadBulk.OFFLINE)
        } else {
          this.setErrUploadBulky(error.error.message.en)
        }

        Vue.notify({
          title: 'Submit Bulk Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingSubmitBulk(false)
      })
  }

  @Action({ rawError: true })
  public getManageCourierHistoryUploadBulkList(
    params: Record<string, string | number>
  ): void {
    this.setLoadingHistoryUploadBulk(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )
    this.presenter
      .getHistoryUploadBulky(formattedParams)
      .then(res => {
        this.setManageCourierHistoryUploadBulkData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manage Courier History Upload Bulk Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingHistoryUploadBulk(false)
      })
  }

  @Action({ rawError: true })
  public getManageCourierDetailHistoryUploadBulk(
    params: Record<string, string | number>
  ): void {
    this.setLoadingGetUploadBulkData(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )
    this.presenter
      .getDetailHistoryUploadBulky(formattedParams)
      .then(res => {
        this.setManageCourierUploadBulk(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Detail History Upload Bulk Data List Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingGetUploadBulkData(false)
      })
  }

  @Action({ rawError: true })
  public getTruckVendorList(params: {
    params:  Record<string, string | number>,
    type: string
  }): void {
    this.setIsTruckVendorLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params.params),
      'snake_case'
    )
    this.presenter
      .getTruckVendorSuggestions(formattedParams)
      .then(res => {
        if (params.type === 'id') {
          this.setTruckVendorIdData(res)
        } else {
          this.setTruckVendorNameData(res)
        }
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Truck Vendor List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000
        })
      })
      .finally(() => {
        this.setIsTruckVendorLoading(false)
      })
  }

  @Action({ rawError: true })
  public getCourierLeaveRequestList(params: Record<string, string | number | undefined>): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getCourierLeaveRequest(formattedParams)
      .then(res => {
        this.setCourierLeaveRequestData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Courier Leave Request List Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getCourierLeaveTotalRequest(): void {
    this.presenter
      .getCourierLeaveTotalRequest()
      .then(res => {
        this.setTotalLeaveRequest(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Total Courier Leave Request Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000
        })
      })
  }

  @Action({ rawError: true })
  public submitCourierLeaveApproval(params: {
    leaveId: string,
    status: string
    note: string
  }): void {
    this.setLoading(true)

    const payload = new CourierLeaveApproval(
      parseInt(params.leaveId),
      params.status,
      params.note
    )

    this.presenter
      .submitCourierLeaveApproval(payload)
      .then(() => {
        if (params.status === 'APPROVED') {
          this.setSuccessApproveCourierLeave(true)
        } else {
          this.setSuccessRejectCourierLeave(true)
        }
      })
      .catch(error => {
        Vue.notify({
          title: 'Submit Courier Leave Approval Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error'
        })
        this.setFailedSubmitCourierLeave(true)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setManageCourierData(manageCouriers: ManageCouriers): void {
    this.paginationData = <Pagination>manageCouriers.pagination
    this.manageCourierData = <ManageCourier[]>manageCouriers.data
  }

  @Mutation
  private setPartnerData(partners: Partner[]): void {
    this.partnerData = <Partner[]>partners
  }

  @Mutation
  private setManageCourierAdjustSaldoData(
    manageCouriersAdjustSaldo: ManageCouriersAdjustSaldo
  ): void {
    this.paginationData = <Pagination>manageCouriersAdjustSaldo.pagination
    this.manageCourierAdjustSaldoData = <ManageCourierAdjustSaldo[]>(
      manageCouriersAdjustSaldo.data
    )
  }

  @Mutation
  private setManageCourierHistoryLogData(
    manageCourierHistoryLog: ManageCourierHistoryLogs
  ): void {
    this.paginationData = <Pagination>manageCourierHistoryLog.pagination
    this.manageCourierHistoryLogData = <ManageCourierHistoryLog>(
      manageCourierHistoryLog.data
    )
  }

  @Mutation
  private setManageCourierOnLeaveData(
    manageCourierOnLeave: ManageCourierOnLeaves
  ): void {
    this.paginationData = <Pagination>manageCourierOnLeave.pagination
    this.manageCourierOnLeaveData = <ManageCourierOnLeave[]>(
      manageCourierOnLeave.data
    )
  }

  @Mutation
  private setManageCourierHistoryUploadBulkData(
    manageCourierHistoryBulk: ManageCourierHistoryUploadBulks
  ): void {
    this.paginationData = <Pagination>manageCourierHistoryBulk.pagination
    this.manageCourierHistoryUploadBulkData = <
      ManageCourierHistoryUploadBulk[]
    >manageCourierHistoryBulk.data
  }

  @Mutation
  private setCourierDetail(manageCourierDetail: ManageCourierDetail): void {
    this.manageCourierDetail = manageCourierDetail
  }

  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingExport(bool: boolean): void {
    this.isLoadingExport = bool
  }

  @Mutation
  private setLoadingDownload(bool: boolean): void {
    this.isLoadingDownload = bool
  }

  @Mutation
  private setLoadingHistoryUploadBulk(bool: boolean): void {
    this.isLoadingGetHistoryUploadBulk = bool
  }

  @Mutation
  private setLoadingGetUploadBulkData(bool: boolean): void {
    this.isLoadingGetUploadBulkData = bool
  }

  @Mutation
  public setLoadingSubmitBulk(bool: boolean): void {
    this.isLoadingSubmitBulk = bool
  }

  @Mutation
  public setModalSuccess(bool: boolean): void {
    this.modalSuccessAdd = bool
  }

  @Mutation
  public setModalSuccessEdit(bool: boolean): void {
    this.modalSuccessEdit = bool
  }

  @Mutation
  public setExportParams(value: IExportParams): void {
    this.exportParams = value
  }

  @Mutation
  public setFilenameBulkUpload(filename: string): void {
    this.filenameBulkUpload = filename
  }

  @Mutation
  public setStatusUpload(status: EnumStatusUpload): void {
    this.statusUpload = status
  }

  @Mutation
  public setManageCourierUploadBulk(data: ManageCourierUploadBulk): void {
    this.dataUploadBulky = data
  }

  @Mutation
  public setManageCourierUploadBulkyPagination(pagination: Pagination): void {
    this.uploadBulkPaginationData = <Pagination>pagination
  }

  @Mutation
  public setErrUploadBulky(err: string): void {
    this.errUploadBulky = err
  }

  @Mutation
  public setErrSubmitBulky(err: string): void {
    this.errSubmitBulky = err
  }

  @Mutation
  public setTruckVendorIdData(data: TruckVendor[]): void {
    this.truckVendorIdData = data
  }

  @Mutation
  public setTruckVendorNameData(data: TruckVendor[]): void {
    this.truckVendorNameData = data
  }

  @Mutation
  public setIsTruckVendorLoading(bool: boolean): void {
    this.isTruckVendorLoading = bool
  }

  @Mutation
  public setCourierLeaveRequestData(data: ManageCourierLeaveRequests): void {
    this.courierLeaveRequestData = <ManageCourierLeaveRequest[]>data.data
    this.paginationData = <Pagination>data.pagination
  }

  @Mutation
  public setTotalLeaveRequest(data: number): void {
    this.totalLeaveRequest = data
  }

  @Mutation
  public setSuccessApproveCourierLeave(bool: boolean): void {
    this.isSuccessApproveCourierLeave = bool
  }

  @Mutation
  public setSuccessRejectCourierLeave(bool: boolean): void {
    this.isSuccessRejectCourierLeave = bool
  }

  @Mutation
  public setFailedSubmitCourierLeave(bool: boolean): void {
    this.isFailedSubmitCourierLeave = bool
  }

}

export default getModule(ManageCourierController)
