




























import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import IconAskCircle from '@/app/ui/assets/icon_ask_circle.vue'

@Component({
  components: {
    Modal,
    Button,
    IconAskCircle
  },
})
export default class ModalConfirmation extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: 'Konfirmasi Data CRRNFD' }) private title!: string
  @Prop({
    default: 'Pastikan data yang tertera sudah sesuai dengan data aslinya'
  }) private description!: string
  @Prop({ default: 'Cek Ulang' }) private btnCancelText!: string
  @Prop({ default: 'Ya, Sesuai' }) private btnConfirmText!: string
}
