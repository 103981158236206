
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CloseLineIcon from '@/app/ui/assets/close_line.vue'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  name: 'inputMultiTags',
  components: {
    CloseLineIcon,
  },
})
export default class CustomInputMultiTags extends Vue {
  @Prop({ type: String, default: 'input-tag' }) id!: string
  @Prop({ type: String, default: 'Variant options cannot be the same' })
  errorMessageTag!: string
  @Prop({ type: Boolean, default: true }) canDelete!: boolean
  @Prop({ type: Boolean, default: true }) canEnter!: boolean
  @Prop({ type: Boolean, default: false }) hideErrorDuplicate!: boolean
  @Prop({ type: Boolean, default: false }) isError!: boolean
  @Prop({ default: [] }) customAddKey!: string[]
  @Prop({ default: false }) showLength!: boolean
  @Prop({ default: '# Item' }) lengthTextFormat!: string
  @Prop({ default: false }) useMaxLength!: boolean
  @Prop({ default: 0 }) maxLength!: number
  @Prop({ default: ''}) errorMessageMaxLength!: string

  textInput = ''
  errorMsg = ''

  get listTags(): string[] {
    return [...this.$attrs.value]
  }

  private onFocus(): void {
    const elInputRef = <HTMLInputElement>this.$el.querySelector(`#${this.id}`)
    if (elInputRef) {
      elInputRef.focus()
    }
  }

  private onAddValue(): void {
    const modelValue = this.$attrs.value
    const payload: Set<string> = new Set()
    const tempPayload: Array<string> = []
    const duplicates: Array<string> = []
    let isShipmentIdExist = false

    this.textInput
      .trim()
      .replaceAll(/\s+/g, ';')
      .split(';')
      .forEach(item => {
        if (item) {
          tempPayload.push(item.toUpperCase())
        }
      })

    for (const item of [...modelValue, ...tempPayload]) {
      if (payload.has(item)) {
        duplicates.push(item)
      } else {
        payload.add(item)
      }
    }

    if (duplicates.length !== 0) {
      isShipmentIdExist = true
    }

    // for user one by one press enter
    if(this.useMaxLength && modelValue.length > this.maxLength -1) {
      this.$emit('getError', true)
      this.errorMsg = this.errorMessageMaxLength
      return;
    }

    if (this.textInput) {
      this.$emit('update:modelValue', [...payload])
      if (!isShipmentIdExist && this.canEnter && !this.hideErrorDuplicate) {
        this.textInput = ''
        this.$emit('getError', false)
      } else {
        this.textInput = duplicates.join(';')
        this.$emit('getError', true)
        this.errorMsg = this.errorMessageTag
      }
    }

    // for user copy paste for detection one press enter
    if(payload.size > this.maxLength) {
      this.$emit('getError', true)
      this.errorMsg = this.errorMessageMaxLength
    }
  }

  private onInput(event: { target: { value: string } }): void {
    this.errorMsg = ''
    const lastInput = event.target.value
      .split('')
      .splice(event.target.value.length - 1)

    const elInputRef = document.getElementById('input-tag')
    const OS = Utils.getOS()
    if (elInputRef && !['Mac OS', 'iOS'].includes(OS)) {
      elInputRef.setAttribute('size', String(this.textInput.length) || '1')
    }

    if (
      this.customAddKey.length !== 0 &&
      this.customAddKey.includes(lastInput[0])
    ) {
      this.onAddValue()
    }

    this.$emit('input', event.target.value)
  }

  private onDeleteTag(idx: number): void {
    const filterDelete = this.listTags.filter((_, index) => index !== idx)
    this.errorMsg = ''
    this.$emit('getError', false)
    this.$emit('update:modelValue', filterDelete)

    if(this.listTags.length > this.maxLength + 1) {
      this.$emit('getError', true)
        this.errorMsg = this.errorMessageMaxLength
    }
  }

  private handleTextAreaHeight(): void {
    const textAreaEl = <HTMLTextAreaElement>this.$el.getElementsByClassName('text-area')[0]

    if (textAreaEl) {
      /*
        there is a slight delay with textarea scrollHeight to be updated to
        latest textarea value (height) on keyup enter event.

        this cause an issue when we want to set textarea area height, it
        will set it to previous scrollHeight before value updated instead
        of after value updated.

        to prevent this issue, a slight timeout is added.
      */
      setTimeout(() => {
        textAreaEl.style.height = '2rem'
        textAreaEl.style.height = (textAreaEl.scrollHeight)+"px";
      }, 1)
    }
  }

  @Watch('textInput')
  onSetTextAreaHeight(): void {
    this.handleTextAreaHeight()
  }
}
