


















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import TimePicker from '@/app/ui/components/TimePicker/index.vue'
import ArrowDown from '@/app/ui/assets/CampaignRamadhan/arrow_down_icon.vue'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  components: {
    DateTimePickerV2,
    TimePicker,
    ArrowDown,
  },
})
export default class ScheduleAssigneCourier extends Vue {
  @Prop({ default: null }) private value!: Date
  @Prop({ default: false }) private toggle!: boolean

  isActive = false
  dateValue: Date | null = null
  minuteOptions = [0, 30]

  mounted(): void {
    this.dateValue = new Date(this.value)
    this.isActive = this.toggle
  }

  get timePickerValue(): Date | null {
    return this.value ? new Date(this.value) : null
  }

  get formatedDateLabel(): string {
    return Utils.formatDateWithIDLocale(
      new Date(this.dateValue as Date).toISOString(),
      'DD MMMM YYYY'
    )
  }

  get hoursNow(): number {
    return this.isOverThirtyMinute
      ? parseInt(Utils.formatDateWithIDLocale(new Date().toISOString(), 'HH')) +
          1
      : parseInt(Utils.formatDateWithIDLocale(new Date().toISOString(), 'HH'))
  }

  get isTodayDate(): boolean {
    return (
      !!this.dateValue &&
      this.dateValue.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
    )
  }

  get hourOptions(): Array<string | number> {
    return this.isTodayDate
      ? Array.from(
          {
            length: 24 - this.hoursNow,
          },
          (_x, i) => i + this.hoursNow
        )
      : Array.from({ length: 24 }, (_x, i) => i)
  }

  get isOverThirtyMinute(): boolean {
    return !!(
      parseInt(Utils.formatDateWithIDLocale(new Date().toISOString(), 'mm')) >
      30
    )
  }

  private onToggleSwitch(): void {
    this.isActive = !this.isActive
    if (!this.isActive) {
      this.$emit('input')
      this.dateValue = null
    }

    this.$emit('toggle', this.isActive)
  }
  private onInput(date: Date): void {
    if (date){
      this.dateValue = new Date(date)
      if (this.dateValue.getHours() === 0 && this.isTodayDate) {
        this.dateValue.setHours(this.hoursNow, date.getMinutes())
        this.$emit('input', new Date(this.dateValue))
      } else {
        this.dateValue.setHours(date.getHours(), date.getMinutes())
        this.$emit('input', new Date(this.dateValue))
      }
    } else {
      this.$emit('input')
    }
  }
}
