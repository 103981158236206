import { EnumOption } from '@/data/infrastructures/misc/interfaces/manageCourier'

export enum EnumTypeCourier {
  KVP = 'KVP',
  POS = 'POS',
  TRUCK = 'TRUCK',
}

export enum EnumTypeVehicle {
  MOTORCYCLE = 'MOTORCYCLE',
  TRUCK = 'TRUCK',
}

export type EnumOptions = Array<EnumOption>

export enum EnumErrorUploadBulk {
  INVALID_FILE = 'Invalid file',
  OFFLINE = 'offline',
  UNKNOWN = 'unknown',
}

export enum EnumAlertMessage {
  VALID = 'Semua data telah sesuai. Silahkan tekan tombol Kirim Data untuk mengirim data',
  INVALID = '### baris data tidak valid. Silahkan perbaiki data excel Anda & upload ulang',
}

export enum EnumInvalidReason {
  COURIER_ID_NOT_FOUND_DESC = 'Courier ID tidak ditemukan',
}

export enum EnumStatusUpload {
  START = 'Starting',
  UPLOADING = 'Uploading',
  PROCESSING = 'Processing',
  COMPLETE = 'Complete',
  FAILED = 'Failed',
}

export enum EnumManageCourierRoute {
  MANAGE_COURIER_PAGE = 'ManageCourierPageList',
  UPLOAD_BULKY_PAGE = 'ManageCourierUploadBulkyPage',
  UPLOAD_BULKY_LIST_PAGE = 'ManageCourierUploadBulkyPageList',
  HISTORY_UPLOAD_BULKY_DETAIL_PAGE = 'ManageCourierHistoryUploadBulkyPageDetail',
  HISTORY_UPLOAD_BULKY_LIST_PAGE = 'ManageCourierHistoryUploadBulkyPageList',
}

export enum CourierLeaveApprovalSortFields {
  COURIER_NAME = 'courier_name',
  DATE_LEAVE = 'date_leave',
}

export enum CourierLeaveApprovalSortType {
  COURIER_NAME_ASC = 'courier_name-asc',
  COURIER_NAME_DESC = 'courier_name-desc',
  DATE_LEAVE_ASC = 'date_leave-asc',
  DATE_LEAVE_DESC = 'date_leave-desc',
}
