import { AddCourierRequestInterface, CourierLeaveApprovalInterface, EditCourierRequestInterface, TruckVendorPayload, UploadBulkyManageCourierLeaveRequestInterface } from '@/data/payload/contracts/ManageCourierRequest'
import { CourierOnLeave } from '@/domain/entities/ManageCourier'
  
  export class AddCourierRequest implements AddCourierRequestInterface {
    private fullname: string
    private phoneNumber: string
    private courierType: string
    private vehicleType: string
    private vehiclePlate: string
    private partnerName: string
    private bankAccountName: string
    private bankId: string
    private bankAccount: string
    private announcementCourierType: string
    private truckVendor?: TruckVendorPayload
    private courierIsCod?: string

  
    constructor( 
      fullname: string,
      phoneNumber: string,
      courierType: string,
      vehicleType: string,
      vehiclePlate: string,
      partnerName: string,
      bankAccountName: string,
      bankId: string,
      bankAccount: string,
      announcementCourierType: string,
      truckVendor?: TruckVendorPayload,
      courierIsCod?: string,
    ) {
      this.fullname = fullname
      this.phoneNumber = phoneNumber
      this.courierType = courierType
      this.vehicleType = vehicleType
      this.vehiclePlate = vehiclePlate
      this.partnerName = partnerName
      this.bankAccountName = bankAccountName
      this.bankId = bankId
      this.bankAccount = bankAccount
      this.announcementCourierType = announcementCourierType
      this.truckVendor = truckVendor
      this.courierIsCod = courierIsCod
    }
  
    public toPayload(): string {
      return JSON.stringify({
        fullname: this.fullname,
        phone_number: this.phoneNumber,
        courier_type: this.courierType,
        vehicle_type: this.vehicleType,
        vehicle_plate: this.vehiclePlate,
        partner_name: this.partnerName,
        bank_account_name: this.bankAccountName,
        bank_id: this.bankId,
        bank_account: this.bankAccount,
        announcement_courier_type: this.announcementCourierType,
        truck_vendor: this.truckVendor?.vendorId || this.truckVendor?.vendorName ? {
          vendor_id: this.truckVendor?.vendorId,
          vendor_name: this.truckVendor?.vendorName,
        } : null,
        courier_is_cod: JSON.parse(this.courierIsCod as string),
      })
    }
  }

  export class EditCourierRequest implements EditCourierRequestInterface {
    private fullname: string
    private phoneNumber: string
    private courierType: string
    private courierStatus: string
    private vehicleType: string
    private vehiclePlate: string
    private partnerName: string
    private bankAccountName: string
    private bankId: string
    private bankAccount: string
    private announcementCourierType: string
    private timeZone: string
    private courierOnLeave?: CourierOnLeave[]
    private truckVendor?: TruckVendorPayload
    private courierIsCod?: string
  
    constructor( 
      fullname: string,
      phoneNumber: string,
      courierType: string,
      courierStatus: string,
      vehicleType: string,
      vehiclePlate: string,
      partnerName: string,
      bankAccountName: string,
      bankId: string,
      bankAccount: string,
      announcementCourierType: string,
      timeZone: string,
      courierOnLeave?: CourierOnLeave[],
      truckVendor?: TruckVendorPayload,
      courierIsCod?: string 
    ) {
      this.fullname = fullname
      this.phoneNumber = phoneNumber
      this.courierType = courierType
      this.courierStatus = courierStatus
      this.vehicleType = vehicleType
      this.vehiclePlate = vehiclePlate
      this.partnerName = partnerName
      this.bankAccountName = bankAccountName
      this.bankId = bankId
      this.bankAccount = bankAccount
      this.announcementCourierType = announcementCourierType
      this.timeZone = timeZone
      this.courierOnLeave = courierOnLeave
      this.truckVendor = truckVendor
      this.courierIsCod = courierIsCod
    }
  
    public toPayload(): string {
      return JSON.stringify({
        fullname: this.fullname,
        phone_number: this.phoneNumber,
        courier_type: this.courierType,
        courier_status: this.courierStatus,
        vehicle_type: this.vehicleType,
        vehicle_plate: this.vehiclePlate,
        partner_name: this.partnerName,
        bank_account_name: this.bankAccountName,
        bank_id: this.bankId,
        bank_account: this.bankAccount,
        announcement_courier_type: this.announcementCourierType,
        time_zone: this.timeZone,
        courier_on_leave: this.courierOnLeave?.map(item => ({
          id: item.id,
          date_leave: item.dateLeave,
          start_time: item.startTime,
          end_time: item.endTime,
          is_custom_leave: item.isCustomLeave,
          is_delete: item.isDelete
        })),
        truck_vendor: this.truckVendor?.vendorId || this.truckVendor?.vendorName ? {
          vendor_id: this.truckVendor?.vendorId,
          vendor_name: this.truckVendor?.vendorName,
        } : null,
        courier_is_cod: this.courierIsCod ? JSON.parse(this.courierIsCod) : null
      })
    }
  }

  export class UploadBulkyManageCourierLeaveRequest implements UploadBulkyManageCourierLeaveRequestInterface {
    public file?: File

    constructor(file?: File) {
      this.file = file
    }

    public toPayload(): FormData {
      const form = new FormData()
      form.append('file', <File>this.file)
      return form
    }
  }

  export class CourierLeaveApproval implements CourierLeaveApprovalInterface {
    public leaveId: number
    public statusApproval: string
    public note?: string

    constructor(
      leaveId: number,
      statusApproval: string,
      note?: string
    ) {
      this.leaveId = leaveId
      this.statusApproval = statusApproval
      this.note = note
    }

     public toPayload(): string {
        return JSON.stringify({
            leave_id: this.leaveId,
            status_approval: this.statusApproval,
            note: this.note
        })
    }
  }
