






















import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import IconSuccessCircle from '@/app/ui/assets/icon_check_circle.vue'

@Component({
  components: {
    Modal,
    Button,
    IconSuccessCircle
  },
})
export default class ModalSuccess extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: 'Data CRRNFD Berhasil Dikirim' }) private title!: string
  @Prop({ default: 'Data yang anda kirim telah berhasil' }) private description!: string
}
